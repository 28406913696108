/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Text,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import MicroStatistics from "components/card/MicroStatistics";
import MacroStatistics from "components/card/MacroStatistics";
import MediumStatistics from "components/card/MediumStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdPeopleAlt,
} from "react-icons/md";
import PieCard from "views/admin/default/components/PieCard";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import axios from "axios";
import { selectedDataRecoil, selectedHeadFundsRecoil } from "atom/DataFromAPI";
import { useRecoilValue } from "recoil";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [data, setData] = React.useState([]);
  const selectedDataFromAPI = useRecoilValue(selectedDataRecoil);
  const selectedHeadFund = useRecoilValue(selectedHeadFundsRecoil);

  const getInfo = async () => {
    let info;
    if (selectedHeadFund == "default") {
      info = await axios.get(
        `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView`
      );
    } else {
      info = await axios.get(
        `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView&filtros=convert(varchar(max),decryptbykey(_cryptNome))='${selectedHeadFund}'`
      );
    }
    setData(info.data);
  };

  React.useEffect(() => {
    getInfo();
  }, [selectedHeadFund]);

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="20px" mb="20px">
        <MiniStatistics
          id="ValorInvestido"
          startContent={
            <IconBox
              w="25px"
              h="25px"
              bg={boxBg}
              icon={
                <Icon w="20px" h="20px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Valor investido"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).valorinvestidonominal
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.valorinvestidonominal,
                  0
                )
          )}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="25px"
              h="25px"
              bg={boxBg}
              icon={
                <Icon w="20px" h="20px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Valor negociado (Liquidado + Acordo)"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).valornegociado
              : data.reduce(
                  (accumulator, value) => accumulator + value.valornegociado,
                  0
                )
          )}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="25px"
              h="25px"
              bg={boxBg}
              icon={
                <Icon w="20px" h="20px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Juros prime apurado (2,15% a.m.)"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).jurosprimeapurado
              : data.reduce(
                  (accumulator, value) => accumulator + value.jurosprimeapurado,
                  0
                )
          )}
        />
        <MediumStatistics
          startContent={<Text fontSize={"md"}>Valor Total inadimplente</Text>}
          name="Valor corrigido"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).titulosemaberto_totalcorrigido
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.titulosemaberto_totalcorrigido,
                  0
                )
          )}
          secondLabel={"Valor original"}
          secondValue={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).titulosemaberto_valorprincipal
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.titulosemaberto_valorprincipal,
                  0
                )
          )}
        />
        <MacroStatistics
          startContent="Total de títulos"
          firstStatistics={
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).titulosemaberto_qtd
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.titulosemaberto_qtd,
                  0
                )
          }
          firstStatisticsLabel="Titulos Inadimplentes"
          secondStatistics={
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).titulosnegociados
              : data.reduce(
                  (accumulator, value) => accumulator + value.titulosnegociados,
                  0
                )
          }
          secondStatisticsLabel="Titulos Negociados"
          thirdStatistics={
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).totaldetitulos
              : data.reduce(
                  (accumulator, value) => accumulator + value.totaldetitulos,
                  0
                )
          }
          thirdStatisticsLabel="Total de Títulos"
        />
        <MacroStatistics
          startContent="Taxa de rendimento total nas negociações"
          firstStatistics={`${
            Math.floor(
              selectedDataFromAPI != "total"
                ? data.find(
                    (value) => value.operacaodescricao == selectedDataFromAPI
                  ).taxarendimentotitulosliquidados * 100
                : (data.reduce(
                    (accumulator, value) =>
                      accumulator + value.taxarendimentotitulosliquidados,
                    0
                  ) /
                    data.length) *
                    100
            ) / 100
          }%`}
          firstStatisticsLabel="Títulos de liquidados"
          secondStatistics={`${
            Math.floor(
              selectedDataFromAPI != "total"
                ? data.find(
                    (value) => value.operacaodescricao == selectedDataFromAPI
                  ).taxarendimentotitulosliquidadosacordo * 100
                : (data.reduce(
                    (accumulator, value) =>
                      accumulator + value.taxarendimentotitulosliquidadosacordo,
                    0
                  ) /
                    data.length) *
                    100
            ) / 100
          }%`}
          secondStatisticsLabel="Títulos liquidados / acordo"
          thirdStatistics={`${
            Math.floor(
              selectedDataFromAPI != "total"
                ? data.find(
                    (value) => value.operacaodescricao == selectedDataFromAPI
                  ).taxarendimentosomatotal * 100
                : (data.reduce(
                    (accumulator, value) =>
                      accumulator + value.taxarendimentosomatotal,
                    0
                  ) /
                    data.length) *
                    100
            ) / 100
          }%`}
          thirdStatisticsLabel="Soma Total"
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1 }} gap="20px" mb="20px">
        <DailyTraffic />
        {/* <PieCard /> */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 2, md: 5, xl: 5 }} gap="20px" mb="20px">
        <MicroStatistics
          startContent={data[0]?.atrasomedio}
          name="Idade Média da Carteira Inadimplente"
        />
        <MicroStatistics
          startContent=" - "
          name="Unidades Inadimplentes no último mês"
        />
        <MicroStatistics
          startContent=" - "
          name="Contatos realizados no último mês"
        />
        <MicroStatistics
          startContent=" - "
          name="Acordos Gerados no ultimo mês"
        />
        <MicroStatistics startContent=" - " name="Execuções em andamento" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, xl: 2 }} gap="20px" mb="20px">
        <MacroStatistics
          startContent="Liquidação Direta"
          firstStatistics={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).titulospagossemacordo_valorpago
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.titulospagossemacordo_valorpago,
                  0
                )
          )}
          secondStatistics={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).titulospagossemacordo_valorinvestido
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.titulospagossemacordo_valorinvestido,
                  0
                )
          )}
          secondStatisticsLabel="Principal Investido Liquidado"
          thirdStatistics={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).receitatotalliquidada
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.receitatotalliquidada,
                  0
                )
          )}
          thirdStatisticsLabel="Receita total liquidada"
        />
        <MacroStatistics
          startContent="Liquidação por Acordos"
          firstStatistics={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).parcelasoriginais_valorliquidado
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.parcelasoriginais_valorliquidado,
                  0
                )
          )}
          secondStatistics={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).parcelasoriginais_valorprincipal
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.parcelasoriginais_valorprincipal,
                  0
                )
          )}
          secondStatisticsLabel="Principal Liquidado por acordo"
          thirdStatistics={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).receitaliquidadoporacordo
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.receitaliquidadoporacordo,
                  0
                )
          )}
          thirdStatisticsLabel="Receira Liquidado por acordo"
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="20px" mb="20px">
        <MediumStatistics
          id="AcordosLiquidados"
          startContent={
            <Text fontSize={"md"}>Parcelas de acordos liquidados</Text>
          }
          name="Valor total de acordos liquidados"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? 0
              : data[0]?.acordosparcelasliquidadas_valorprincipal
          )}
          secondLabel={"Acordos liquidados"}
          secondValue={
            selectedDataFromAPI != "total"
              ? 0
              : data[0]?.acordoparcelasliquidadas_qtd
          }
        />
        <MediumStatistics
          startContent={
            <Text fontSize={"md"}>Parcelas de acordos a vencer</Text>
          }
          name="Valor total de acordos a vencer"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? 0
              : data[0]?.acordosparcelasavencer_valorprincipal
          )}
          secondLabel={"Acordos a vencer"}
          secondValue={
            selectedDataFromAPI != "total"
              ? 0
              : data[0]?.acordoparcelasavencer_qtd
          }
        />
        <MediumStatistics
          startContent={
            <Text fontSize={"md"}>Parcela de acordos inadimplentes</Text>
          }
          name="Valor total de acordos inadimplentes"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? 0
              : data[0]?.acordosparcelasematraso_valorprincipal
          )}
          secondLabel={"Acordos inadimplentes"}
          secondValue={
            selectedDataFromAPI != "total"
              ? 0
              : data[0]?.acordoparcelasematraso_qtd
          }
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, "2xl": 4 }} gap="20px" mb="20px">
        <MiniStatistics
          startContent={<Text fontSize={"md"}>Valor total da carteira</Text>}
          name="Total inadimplente corrigido + total negociado"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).valortotaldacarteira
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.valortotaldacarteira,
                  0
                )
          )}
        />
        <MiniStatistics
          startContent={<Text fontSize={"md"}>Custo total da operação</Text>}
          name="Valor total investido + Juros prime apurado"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).custototaloperacao
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.custototaloperacao,
                  0
                )
          )}
        />
        <MiniStatistics
          startContent={<Text fontSize={"md"}>Receita total projetada</Text>}
          name="Valor total da carteira - Valor investido sem correção"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).receitatotalprojetada
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.receitatotalprojetada,
                  0
                )
          )}
        />
        <MiniStatistics
          startContent={<Text fontSize={"md"}>Receita liquida projetada</Text>}
          name="Valor total da carteira - Custo total da operação"
          value={formatter.format(
            selectedDataFromAPI != "total"
              ? data.find(
                  (value) => value.operacaodescricao == selectedDataFromAPI
                ).receitaliquidaprojetada
              : data.reduce(
                  (accumulator, value) =>
                    accumulator + value.receitaliquidaprojetada,
                  0
                )
          )}
        />
      </SimpleGrid>
    </Box>
  );
}
