import {
  Flex,
  Table,
  Button,
  Input,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Tfoot,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  Column,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
} from "@tanstack/react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import moment from "moment";

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  function formataCPF(cpf) {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");
    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card px="0px" minW w>
      <Flex direction="Row">
        <Flex direction="column" w="100%">
          <Flex px="25px" justify="center" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Lista de operações
            </Text>
          </Flex>
          <Table variant="simple" color="gray.500">
            <Thead>
              {tableInstance.getHeaderGroups().map((headerGroup, index) => (
                <Tr key={index}>
                  {headerGroup.headers.map((header, index) => (
                    <Th pe="10px" key={index} borderColor={borderColor}>
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                        cursor={"pointer"}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {tableInstance.getRowModel().rows.map((row, index) => {
                return (
                  <Tr
                    key={index}
                    backgroundColor={
                      index % 2 == 0 ? "blackAlpha.100" : "white"
                    }
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      let value = cell.getValue();
                      let data;
                      if (
                        cell.column.id == "_valororiginal" ||
                        cell.column.id == "valorinvestidonominal" ||
                        cell.column.id == "titulospagossemacordo_valorpago" ||
                        cell.column.id == "parcelasoriginais_valorliquidado" ||
                        cell.column.id ==
                          "acordoparcelasliquidadas_valorpago" ||
                        cell.column.id == "titulosemaberto_valorprincipal" ||
                        cell.column.id == "acordoparcelasematraso_valortotal" ||
                        cell.column.id == "titulosemaberto_totalcorrigido" ||
                        cell.column.id == "sumEstoqueAcordo" ||
                        cell.column.id == "sumValorRecEstoque"
                      ) {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {formatter.format(value)}
                          </Text>
                        );
                      } else if (cell.column.id == "_data") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {moment(value).format("DD/MM/YYYY")}
                          </Text>
                        );
                      } else
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {value ? value : "-"}
                          </Text>
                        );
                      return (
                        <Td
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot>
              {tableInstance.getFooterGroups().map((footerGroup, index) => (
                <Tr key={index}>
                  {footerGroup.headers.map((header, index) => {
                    if (
                      header.column.id == "valorinvestidonominal" ||
                      header.column.id == "titulospagossemacordo_valorpago" ||
                      header.column.id == "parcelasoriginais_valorliquidado" ||
                      header.column.id == "titulosemaberto_totalcorrigido"
                    ) {
                      const total = data.reduce(
                        (sum, value) => sum + value[header.column.id],
                        0
                      );
                      const totalPassivo = data.reduce(
                        (sum, value) =>
                          value._tipo.includes("PASSI")
                            ? sum + value[header.column.id]
                            : sum,
                        0
                      );
                      const totalOrdinaria = data.reduce(
                        (sum, value) =>
                          value._tipo.includes("ORDIN")
                            ? sum + value[header.column.id]
                            : sum,
                        0
                      );
                      return (
                        <Th pe="10px" key={index} borderColor={borderColor}>
                          <Flex
                            justify="space-between"
                            align="center"
                            fontSize={{ sm: "8px", lg: "10px" }}
                            color="gray.400"
                          >
                            {"Total: " + formatter.format(total)}
                            <br />
                            {"T. P.: " + formatter.format(totalPassivo)}
                            <br />
                            {"T. O.: " + formatter.format(totalOrdinaria)}
                          </Flex>
                        </Th>
                      );
                    } else if (header.column.id == "sumValorRecEstoque") {
                      const total = data.reduce(
                        (sum, value) =>
                          sum -
                          value.valorinvestidonominal +
                          value.titulospagossemacordo_valorpago +
                          value.titulosemaberto_totalcorrigido,
                        0
                      );
                      const totalPassivo = data.reduce(
                        (sum, value) =>
                          value._tipo.includes("PASSI")
                            ? sum -
                              value.valorinvestidonominal +
                              value.titulospagossemacordo_valorpago +
                              value.titulosemaberto_totalcorrigido
                            : sum,
                        0
                      );
                      const totalOrdinaria = data.reduce(
                        (sum, value) =>
                          value._tipo.includes("ORDIN")
                            ? sum -
                              value.valorinvestidonominal +
                              value.titulospagossemacordo_valorpago +
                              value.titulosemaberto_totalcorrigido
                            : sum,
                        0
                      );
                      return (
                        <Th pe="10px" key={index} borderColor={borderColor}>
                          <Flex
                            justify="space-between"
                            align="center"
                            fontSize={{ sm: "8px", lg: "10px" }}
                            color="gray.400"
                          >
                            {"Total: " + formatter.format(total)}
                            <br />
                            {"T. P.: " + formatter.format(totalPassivo)}
                            <br />

                            {"T. O.: " + formatter.format(totalOrdinaria)}
                          </Flex>
                        </Th>
                      );
                    } else
                      return (
                        <Th pe="10px" key={index} borderColor={borderColor}>
                          <Flex
                            justify="space-between"
                            align="center"
                            fontSize={"16px"}
                            color="gray.400"
                          >
                            -
                          </Flex>
                        </Th>
                      );
                  })}
                </Tr>
              ))}
            </Tfoot>
          </Table>
        </Flex>
      </Flex>
      <Flex pt="20px">
        <Button
          onClick={() => tableInstance.firstPage()}
          disabled={!tableInstance.getCanPreviousPage()}
        >
          {"<<"}
        </Button>
        <Button
          onClick={() => tableInstance.previousPage()}
          disabled={!tableInstance.getCanPreviousPage()}
        >
          {"<"}
        </Button>
        <Button
          onClick={() => tableInstance.nextPage()}
          disabled={!tableInstance.getCanNextPage()}
        >
          {">"}
        </Button>
        <Button
          onClick={() => tableInstance.lastPage()}
          disabled={!tableInstance.getCanNextPage()}
        >
          {">>"}
        </Button>
        <Text
          style={{
            alignItems: "center",
            display: "flex",
            marginLeft: "10px",
            marginRight: "10px",
          }}
          gap={"10px"}
        >
          {"Página"}
          <strong>
            {tableInstance.getState().pagination.pageIndex + 1} de{" "}
            {tableInstance.getPageCount().toLocaleString()}
          </strong>
        </Text>
        <Text>
          <Input
            type="number"
            defaultValue={tableInstance.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              tableInstance.setPageIndex(page);
            }}
          />
        </Text>
        <select
          value={tableInstance.getState().pagination.pageSize}
          onChange={(e) => {
            tableInstance.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
      </Flex>
    </Card>
  );
}
