/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
// Custom components
import Table from "./components/Table";
import { columnsData } from "./variables/columnsData";
import MacroStatistics from "components/card/MacroStatistics";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import { selectedHeadFundsRecoil } from "atom/DataFromAPI";
import { useRecoilValue } from "recoil";

// Assets
import React from "react";

export default function Overview() {
  const [operationData, setOperationData] = React.useState([]);
  const selectedHeadFund = useRecoilValue(selectedHeadFundsRecoil);

  const getInfo = async () => {
    let info;
    if (selectedHeadFund == "default") {
      info = await axios.get(
        `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView`
      );
    } else {
      info = await axios.get(
        `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView&filtros=convert(varchar(max),decryptbykey(_cryptNome))='${selectedHeadFund}'`
      );
    }
    setOperationData(
      info.data.sort((a, b) =>
        moment(a._data, "YYYY-MM-DD").isAfter(b._data, "YYYY-MM-DD") ? -1 : 1
      )
    );
  };
  React.useEffect(() => {
    getInfo();
  }, [selectedHeadFund]);

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return (
    <Box>
      {/* Main Fields */}
      <SimpleGrid
        spacing={{ base: "20px" }}
        columns={{ sm: 1, md: 2 }}
        mt={{ sm: "20px", xl: "0px" }}
      >
        <MacroStatistics
          startContent="Total de Operações"
          firstStatistics={formatter.format(
            operationData.reduce(
              (add, value) => (add += value.valorinvestidonominal),
              0
            )
          )}
          secondStatistics={formatter.format(
            operationData.reduce(
              (add, value) =>
                (add += value._tipo.includes("PASSIV")
                  ? value.valorinvestidonominal
                  : 0),
              0
            )
          )}
          secondStatisticsLabel="Total de operações passivos"
          thirdStatistics={formatter.format(
            operationData.reduce(
              (add, value) =>
                (add += value._tipo.includes("ORDIN")
                  ? value.valorinvestidonominal
                  : 0),
              0
            )
          )}
          thirdStatisticsLabel="Total de operações ordinárias"
        />
        <MacroStatistics
          startContent="Lucro Total"
          firstStatistics={formatter.format(
            operationData.reduce(
              (add, value) =>
                (add +=
                  -value.valorinvestidonominal +
                  value.titulospagossemacordo_valorpago +
                  value.acordoparcelasavencer_valortotal +
                  value.titulosemaberto_totalcorrigido),
              0
            ) +
              operationData[0]?.acordoparcelasematraso_valortotal +
              operationData[0]?.acordoparcelasliquidadas_valorpago
          )}
          secondStatistics={formatter.format(
            operationData[0]?.acordoparcelasematraso_valortotal
          )}
          secondStatisticsLabel="Acordo parcelas em atraso"
          thirdStatistics={formatter.format(
            operationData[0]?.acordoparcelasliquidadas_valorpago
          )}
          thirdStatisticsLabel="Recebimento acordo"
        />
      </SimpleGrid>
      <Flex mt="20px">
        <Table columnsData={columnsData} tableData={operationData} />
      </Flex>
    </Box>
  );
}
