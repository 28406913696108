export const columnsDataComplex = [
  { header: "Status", accessorKey: "statusparcela" },
  { header: "Participante", accessorKey: "codparticipante" },
  { header: "Condomínio", accessorKey: "condominiodescricao" },
  { header: "Unidade", accessorKey: "_unidade" },
  { header: "CPF", accessorKey: "clientecpfcnpj" },
  { header: "Devedor", accessorKey: "clientenome" },
  { header: "Tipo", accessorKey: "tipotitulo" },
  { header: "Vencimento", accessorKey: "_vencimento" },
  { header: "Atraso", accessorKey: "_diasatraso" },
  { header: "Principal", accessorKey: "principal" },
  { header: "Multa", accessorKey: "multavalor" },
  { header: "Juros", accessorKey: "jurosvalor" },
  { header: "Correção", accessorKey: "moravalor" },
  { header: "Honorários", accessorKey: "honorariovalor" },
  { header: "Valor atualizado", accessorKey: "totalcorrigido" },
  { header: "Operação", accessorKey: "operacaodescricao" },
  { header: "Data Operação", accessorKey: "_data" },
  { header: "Valor Investido", accessorKey: "_valorinvestido" },
  { header: "Juros PRIME", accessorKey: "_6pbank_jurosprime" },
  { header: "Receita Operação", accessorKey: "" },
  { header: "Rendimento", accessorKey: "" },
  { header: "Data liquidação", accessorKey: "_pagamento" },
  { header: "Valor liquidação", accessorKey: "_valorpago" },
  { header: "Receita do título", accessorKey: "receitatitulo" },
  { header: "Data p. contas", accessorKey: "_pcontasdata" },
  { header: "Valor p. contas", accessorKey: "_pcontasvalor" },
  { header: "Data de Abertura", accessorKey: "_dataabertura" },
  { header: "Numero do Processo", accessorKey: "_cryptnumeroprocesso" },
  { header: "Status Processo", accessorKey: "statusprocessodescricao" },
  // { header: "Forma do pagamento", accessorKey: "" },
];
