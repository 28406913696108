import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Image } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import logo from "assets/img/logo.png";

export function SidebarBrand() {
  //   Chakra color mode
  const brandColor = useColorModeValue("brand.500", "white");

  return (
    <Flex align="center" direction="column">
      <Image src={logo} w="125px" my="20px" />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
