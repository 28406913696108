// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";

export default function Default(props) {
  const { startContent, name } = props;
  const textColorSecondary = "black.900";
  const brandColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card
      py="15px"
      style={{
        backgroundColor: "#00000000",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: brandColor,
      }}
    >
      <Flex h="100%" w="100%">
        <Stat align={"center"} justify={"center"} h="100%" w="100%">
          <StatLabel
            lineHeight="100%"
            mt="5px"
            color={brandColor}
            fontSize={{
              base: "sm",
            }}
            textAlign={"initial"}
          >
            {startContent}
          </StatLabel>
          <StatLabel
            lineHeight="100%"
            mt="5px"
            color={textColorSecondary}
            fontSize={{
              base: "xs",
            }}
            textAlign={"initial"}
          >
            {name}
          </StatLabel>
        </Stat>
      </Flex>
    </Card>
  );
}
