export const columnsData = [
  { header: "Operação", accessorKey: "operacaodescricao" },
  { header: "Tipo", accessorKey: "_tipo" },
  { header: "Data", accessorKey: "_data" },
  { header: "Qtd. Títulos", accessorKey: "totaldetitulos" },
  // { header: "Valor Original", accessorKey: "_valororiginal" },
  { header: "Valor Investido", accessorKey: "valorinvestidonominal" },
  { header: "Liq. Normais", accessorKey: "titulospagossemacordo_valorpago" },
  // {
  //   header: "Rec. - Acordo",
  //   accessorKey: "acordoparcelasliquidadas_valorpago",
  // },
  {
    header: "Liq. p/ acordo",
    accessorKey: "parcelasoriginais_valorliquidado",
  },
  { header: "Estoque", accessorKey: "titulosemaberto_totalcorrigido" },
  {
    header: "Lucro",
    accessorKey: "sumValorRecEstoque",
    accessorFn: (row) =>
      [
        -row.valorinvestidonominal,
        row.parcelasoriginais_valorliquidado,
        row.titulospagossemacordo_valorpago,
        row.titulosemaberto_totalcorrigido,
      ].reduce((sum, current) => sum + current, 0),
  },
];
