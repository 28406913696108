// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";

export default function Default(props) {
  const { startContent, endContent, name, growth, value } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card py="15px">
      <Flex h="100%" w="100%">
        <Stat align={"center"} justify={"center"} h="100%" w="100%">
          {startContent}
          <StatNumber
            color={textColor}
            w={"auto"}
            mt="5px"
            fontSize={{
              base: "2xl",
            }}
          >
            {value}
          </StatNumber>
          <StatLabel
            lineHeight="100%"
            mt="5px"
            color={textColorSecondary}
            fontSize={{
              base: "sm",
            }}
          >
            {name}
          </StatLabel>
        </Stat>
      </Flex>
    </Card>
  );
}
