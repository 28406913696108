// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";

export default function Default(props) {
  const {
    startContent,
    name,
    firstStatistics,
    firstStatisticsLabel,
    secondStatistics,
    secondStatisticsLabel,
    thirdStatistics,
    thirdStatisticsLabel,
  } = props;
  const textColorSecondary = "gray.500";
  const brandColor = useColorModeValue("brand.500", "white");

  return (
    <Card py="15px">
      <Flex h="100%" w="100%">
        <Stat align={"center"} justify={"center"} h="100%" w="100%">
          <StatLabel
            lineHeight="100%"
            pb="10px"
            color={"black"}
            fontSize={{
              base: "lg",
            }}
            borderBottomStyle={"solid"}
            borderBottomWidth={1}
            borderBottomColor={"gray.300"}
          >
            {startContent}
          </StatLabel>
        </Stat>
      </Flex>
      <Flex
        h="100%"
        w="100%"
        justifyContent={"space-around"}
        align={"center"}
        mt="5px"
      >
        {firstStatistics ? (
          <>
            <Stat justifyContent={"center"} display={"flex"} w="100%">
              <StatNumber
                color={firstStatisticsLabel ? textColorSecondary : brandColor}
                fontSize={{
                  base: firstStatisticsLabel ? "md" : "3xl",
                }}
              >
                {firstStatistics}
              </StatNumber>
            </Stat>
            {firstStatisticsLabel ? (
              <Stat justifyContent={"center"} display={"flex"} w="100%">
                <StatLabel
                  lineHeight="100%"
                  color={textColorSecondary}
                  fontSize={{
                    base: "md",
                  }}
                  textAlign={"center"}
                >
                  {firstStatisticsLabel}
                </StatLabel>
              </Stat>
            ) : null}
          </>
        ) : null}
      </Flex>
      {secondStatistics ? (
        <>
          <Flex
            h="100%"
            w="100%"
            justifyContent={"space-around"}
            align={"center"}
            mt="5px"
          >
            <Stat justifyContent={"center"} display={"flex"} w="100%">
              <StatNumber
                color={textColorSecondary}
                fontSize={{
                  base: "md",
                }}
              >
                {secondStatistics}
              </StatNumber>
            </Stat>
            <Stat justifyContent={"center"} display={"flex"} w="100%">
              <StatLabel
                lineHeight="100%"
                color={textColorSecondary}
                fontSize={{
                  base: "md",
                }}
                textAlign={"center"}
              >
                {secondStatisticsLabel}
              </StatLabel>
            </Stat>
          </Flex>
        </>
      ) : null}
      {thirdStatistics ? (
        <>
          <Flex
            h="100%"
            w="100%"
            justifyContent={"space-around"}
            align={"center"}
            mt="5px"
          >
            <Stat justifyContent={"center"} display={"flex"} w="100%">
              <StatNumber
                color={firstStatisticsLabel ? brandColor : textColorSecondary}
                fontSize={{
                  base: "md",
                }}
              >
                {thirdStatistics}
              </StatNumber>
            </Stat>
            <Stat justifyContent={"center"} display={"flex"} w="100%">
              <StatLabel
                lineHeight="100%"
                color={firstStatisticsLabel ? brandColor : textColorSecondary}
                fontSize={{
                  base: "md",
                }}
                textAlign={"center"}
              >
                {thirdStatisticsLabel}
              </StatLabel>
            </Stat>
          </Flex>
        </>
      ) : null}
    </Card>
  );
}
