// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";

export default function Default(props) {
  const { startContent, name, secondLabel, value, secondValue } = props;
  const textColor = useColorModeValue("red.700", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card py="15px">
      <Flex h="100%" w="100%">
        <Stat
          align={"center"}
          justify={"center"}
          h="100%"
          w="100%"
          borderBottomStyle={"solid"}
          borderBottomWidth={1}
          borderBottomColor={"gray.300"}
        >
          {startContent}
          <StatNumber
            color={textColor}
            mt="5px"
            fontSize={{
              base: "2xl",
            }}
          >
            {value}
          </StatNumber>
          <StatLabel
            lineHeight="100%"
            my="10px"
            color={textColor}
            fontSize={{
              base: "sm",
            }}
          >
            {name}
          </StatLabel>
        </Stat>
      </Flex>
      <Flex
        h="100%"
        w="100%"
        justifyContent={"space-around"}
        align={"center"}
        mt="5px"
      >
        {secondValue ? (
          <Stat justifyContent={"center"} display={"flex"} w="100%">
            <StatNumber
              color={textColorSecondary}
              fontSize={{
                base: "lg",
              }}
            >
              {secondValue}
            </StatNumber>
          </Stat>
        ) : null}
        <Stat justifyContent={"center"} display={"flex"} w="100%">
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{
              base: "sm",
            }}
            textAlign={"center"}
          >
            {secondLabel}
          </StatLabel>
        </Stat>
      </Flex>
    </Card>
  );
}
