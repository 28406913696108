import { atom } from "recoil";

export const selectedDataRecoil = atom({
  key: "selectedDataRecoil", // unique ID (with respect to other atoms/selectors)
  default: "total", // default value (aka initial value)
});

export const selectedHeadFundsRecoil = atom({
  key: "selectedHeadFundsRecoil", // unique ID (with respect to other atoms/selectors)
  default: "default", // default value (aka initial value)
});

export const filterTableData = atom({
  key: "filterTableData", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});
