/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid, Button, Flex } from "@chakra-ui/react";
import ComplexTable from "views/admin/dataTables/components/ComplexTable";
import { columnsDataComplex } from "views/admin/dataTables/variables/columnsData";
import React, { useEffect, useState } from "react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import { filterTableData } from "atom/DataFromAPI";
import { useRecoilState } from "recoil";
import axios from "axios";

export default function Settings() {
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useRecoilState(filterTableData);
  const [cancelPrevRequest, setCancelPrevRequest] = useState();

  const transformFilterDataInURL = (filterArray) => {
    let url = "";
    filterArray.forEach((value, index) => {
      if (index != 0 && index != filterArray.length - 1) url += " and ";
      let key = Object.keys(value)[0];
      let val = Object.values(value)[0];
      url += key + "='" + val + "'";
    });
    return url;
  };

  const getInfo = async () => {
    try {
      if (cancelPrevRequest) {
        cancelPrevRequest.cancel("Canceling previous request");
      }
      const source = axios.CancelToken.source();
      setCancelPrevRequest(source);

      const dataTable = await axios.get(
        `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_View_Carteira_Titulos&filtros=${transformFilterDataInURL(
          filterData
        )}`,
        {
          cancelToken: source.token,
        }
      );
      if (Array.isArray(dataTable.data)) setTableData(dataTable.data);
    } catch (err) {}
  };

  useEffect(() => getInfo(), [filterData]);

  return (
    <Box>
      <SimpleGrid spacing={{ base: "20px", xl: "20px" }}>
        <Flex flexDirection={"row"} alignItems={"center"}>
          <AdminNavbarLinks
          // onOpen={props.onOpen}
          // logoText={props.logoText}
          // secondary={props.secondary}
          // fixed={props.fixed}
          // scrolled={scrolled}
          />
          {filterData.map((value, index) => {
            let key = Object.keys(value)[0];
            let val = Object.values(value)[0];
            return (
              <Button
                marginLeft={"20px"}
                onClick={() => {
                  let newFilter = [...filterData];
                  newFilter.splice(index, 1);
                  setFilterData(newFilter);
                }}
              >{`${key}: ${val}`}</Button>
            );
          })}
        </Flex>
        <ComplexTable columnsData={columnsDataComplex} tableData={tableData} />
      </SimpleGrid>
    </Box>
  );
}
