import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RtlLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import axios from "axios";
import { RecoilRoot } from "recoil";

const getInfo = async () => {
  try {
    const getUserInfo = JSON.parse(localStorage.getItem("user6p"));
    if (getUserInfo) {
      const signIn = await axios.post(
        "https://dev.guug.com.br/user/painel-6p",
        getUserInfo
      );
      if (signIn.data.login) {
        return <Redirect from="/" to="/admin" />;
      } else throw new Error("Login invalido");
    } else throw new Error("Login invalido");
  } catch (err) {
    localStorage.removeItem("user6p");
    return <Redirect from="/" to="/auth" />;
  }
};

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <RecoilRoot>
      <React.StrictMode>
        <ThemeEditorProvider>
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />
              {/* <Route path={`/rtl`} component={RtlLayout} /> */}
              {await getInfo()}
            </Switch>
          </HashRouter>
        </ThemeEditorProvider>
      </React.StrictMode>
    </RecoilRoot>
  </ChakraProvider>,
  document.getElementById("root")
);
