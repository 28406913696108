// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
  useColorModeValue,
  createStandaloneToast,
} from "@chakra-ui/react";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import routes from "routes.js";
import {
  selectedDataRecoil,
  filterTableData,
  selectedHeadFundsRecoil,
} from "atom/DataFromAPI";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import axios from "axios";
import moment from "moment";
import { columnsDataComplex } from "views/admin/dataTables/variables/columnsData";

export default function HeaderLinks(props) {
  const { secondary } = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  const [selectedData, setSelectedData] = useRecoilState(selectedDataRecoil);
  const [filterData, setFilterData] = useRecoilState(filterTableData);
  const [selectedHeadFunds, setSelectedHeadFunds] = useRecoilState(
    selectedHeadFundsRecoil
  );
  const [selectedFilterOption, setSelectedFilterOption] =
    React.useState("fundo");
  const [inputFilter, setInputFilter] = React.useState("");
  const [operationData, setOperationData] = React.useState([]);
  const [headFunds, setHeadFunds] = React.useState([]);
  const toast = createStandaloneToast();

  const getInfo = async () => {
    const info = await axios.get(
      "https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView"
    );
    setOperationData(
      info.data.sort((a, b) =>
        moment(a._data, "YYYY-MM-DD").isAfter(b._data, "YYYY-MM-DD") ? 1 : -1
      )
    );
    setFilterData(() => {
      const lastOperation = info.data.sort((a, b) =>
        moment(a._data, "YYYY-MM-DD").isAfter(b._data, "YYYY-MM-DD") ? -1 : 1
      )[0].operacaodescricao;
      return [
        {
          operacaodescricao: lastOperation,
        },
      ];
    });
    setHeadFunds(() => {
      return [...new Set(info.data.map((value) => value._cryptnome))];
    });
  };
  React.useEffect(() => {
    getInfo();
  }, []);

  const nameByEmail = [
    {
      email: "gbacha@chimeracapital.com.br",
      name: "Gabriel Bacha",
    },
    {
      email: "laita@chimeracapital.com.br",
      name: "Luiz Aita",
    },
    {
      email: "cmichels@chimeracapital.com.br",
      name: "Cristina Michels",
    },
    {
      email: "janser@chimeracapital.com.br",
      name: "Janser Saloman",
    },
    {
      email: "bruno.oliveira@6pbank.com.br",
      name: "Bruno Oliveira",
    },
    {
      email: "henrique.moura@6pbank.com.br",
      name: "Henrique Moura",
    },
    { email: "bonfim@6pbank.com.br", name: "Paulo Bonfim" },
  ];
  const getUserInfo = JSON.parse(localStorage.getItem("user6p"));
  const name = nameByEmail.find(
    (value) => value.email == getUserInfo.email
  ).name;

  return (
    <Flex
      w={{ base: "fit-content" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes} />
      {window.location.hash.includes("default") ? (
        <>
          <Select
            fontSize="md"
            variant="subtle"
            width="unset"
            fontWeight="700"
            onChange={(event) => setSelectedData(event.target.value)}
            value={selectedData}
            _hover={{ cursor: "pointer" }}
          >
            <option key={"total"} value={"total"}>
              {"Carteira Total"}
            </option>
            {operationData
              .filter((item) => item.operacaodescricao)
              .map((item) => (
                <option key={Math.random()} value={item.operacaodescricao}>
                  {item.operacaodescricao}
                </option>
              ))}
          </Select>
          <Select
            fontSize="md"
            variant="subtle"
            width="unset"
            fontWeight="700"
            onChange={async (event) => {
              setSelectedHeadFunds(event.target.value);
              if (event.target.value == "default") {
                const info = await axios.get(
                  `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView`
                );
                setOperationData(
                  info.data.sort((a, b) =>
                    moment(a._data, "YYYY-MM-DD").isAfter(b._data, "YYYY-MM-DD")
                      ? -1
                      : 1
                  )
                );
              } else {
                const info = await axios.get(
                  `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView&filtros=convert(varchar(max),decryptbykey(_cryptNome))='${event.target.value}'`
                );
                setOperationData(
                  info.data.sort((a, b) =>
                    moment(a._data, "YYYY-MM-DD").isAfter(b._data, "YYYY-MM-DD")
                      ? -1
                      : 1
                  )
                );
              }
            }}
            value={selectedHeadFunds}
            _hover={{ cursor: "pointer" }}
          >
            <option key={"default"} value={"default"}>
              {"Todos os Fundos"}
            </option>
            {headFunds.map((item) => (
              <option key={Math.random()} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </>
      ) : window.location.hash.includes("data-tables") ? (
        <>
          <Select
            fontSize="md"
            variant="subtle"
            width="unset"
            fontWeight="700"
            _hover={{ cursor: "pointer" }}
            onChange={(event) => setSelectedFilterOption(event.target.value)}
            value={selectedFilterOption}
          >
            <option key={Math.random()} value={"fundo"}>
              {"Fundo"}
            </option>
            {columnsDataComplex.map((value) => (
              <option key={Math.random()} value={value.accessorKey}>
                {value.header}
              </option>
            ))}
          </Select>
          <SearchBar
            mb={secondary ? { base: "10px", md: "unset" } : "unset"}
            me="10px"
            borderRadius="30px"
            onChange={(event) => setInputFilter(event.target.value)}
            value={inputFilter}
            type={
              selectedFilterOption == "_vencimento" ||
              selectedFilterOption == "_data" ||
              selectedFilterOption == "_pcontasdata" ||
              selectedFilterOption == "_pagamento"
                ? "date"
                : undefined
            }
          />
          <Button
            mr="10px"
            border="1px solid"
            bg="transparent"
            borderColor={borderButton}
            _hover={{ cursor: "pointer" }}
            onClick={() => {
              if (inputFilter == "")
                toast({
                  title: "Erro",
                  description: "Filtro não definido",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              else {
                setFilterData([
                  ...filterData,
                  { [selectedFilterOption]: inputFilter },
                ]);
                setInputFilter("");
              }
            }}
            minW
          >
            Adicionar filtro
          </Button>
        </>
      ) : window.location.hash.includes("operacao") ? (
        <Select
          fontSize="md"
          variant="subtle"
          width="unset"
          fontWeight="700"
          onChange={async (event) => {
            setSelectedHeadFunds(event.target.value);
            if (event.target.value == "default") {
              const info = await axios.get(
                `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView`
              );
              setOperationData(
                info.data.sort((a, b) =>
                  moment(a._data, "YYYY-MM-DD").isAfter(b._data, "YYYY-MM-DD")
                    ? -1
                    : 1
                )
              );
            } else {
              const info = await axios.get(
                `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView&filtros=convert(varchar(max),decryptbykey(_cryptNome))='${event.target.value}'`
              );
              setOperationData(
                info.data.sort((a, b) =>
                  moment(a._data, "YYYY-MM-DD").isAfter(b._data, "YYYY-MM-DD")
                    ? -1
                    : 1
                )
              );
            }
          }}
          value={selectedHeadFunds}
          _hover={{ cursor: "pointer" }}
        >
          <option key={"default"} value={"default"}>
            {"Todos os Fundos"}
          </option>
          {headFunds.map((item) => (
            <option key={Math.random()} value={item}>
              {item}
            </option>
          ))}
        </Select>
      ) : null}

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={name}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Ola, {" " + name}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {/* <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Newsletter Settings</Text>
            </MenuItem> */}
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={() => {
                localStorage.removeItem("user6p");
                window.location.reload();
              }}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
