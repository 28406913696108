import React from "react";

// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import BarChart from "components/charts/BarChart";

// Custom components
import Card from "components/card/Card.js";
import axios from "axios";
import moment from "moment";
import { selectedHeadFundsRecoil } from "atom/DataFromAPI";
import { useRecoilValue } from "recoil";

export default function DailyTraffic(props) {
  const { ...rest } = props;
  const [chartDataTitles, setChartDataTitles] = React.useState([]);
  const [chartRows, setChartRows] = React.useState([]);
  const selectedHeadFund = useRecoilValue(selectedHeadFundsRecoil);

  const getInfo = async () => {
    setChartDataTitles([]);
    setChartRows([]);
    let getChartInfo;
    if (selectedHeadFund == "default") {
      getChartInfo = await axios.get(
        `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView`
      );
    } else {
      getChartInfo = await axios.get(
        `https://banco6p.revolutioncrm.com.br:8095/revolutionService/getView?viewName=Temp_Dashboards_View_6PBank_OverView&filtros=convert(varchar(max),decryptbykey(_cryptNome))='${selectedHeadFund}'`
      );
    }
    const chartValueSorted = await getChartInfo.data.sort((a, b) =>
      moment(a._data, "YYYY-MM-DD").isAfter(b._data, "YYYY-MM-DD") ? 1 : -1
    );
    setChartDataTitles(
      chartValueSorted.map((item) => item.valorinvestidonominal)
    );
    setChartRows(chartValueSorted.map((item) => item.operacaodescricao));
  };

  React.useEffect(() => {
    getInfo();
  }, [selectedHeadFund]);

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return chartDataTitles.length && chartRows.length ? (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex justify="space-between" align="start" px="10px" pt="5px">
        <Flex flexDirection="column" align="start" me="20px">
          <Flex w="100%">
            <Text
              me="auto"
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
            >
              Soma das operações
            </Text>
          </Flex>
          <Flex align="end">
            <Text
              color={textColor}
              fontSize="2xl"
              fontWeight="700"
              lineHeight="100%"
            >
              {formatter.format(
                chartDataTitles.reduce((accum, item) => accum + item, 0)
              )}
            </Text>
          </Flex>
        </Flex>
        {/* <Flex align="center">
          <Icon as={RiArrowUpSFill} color="green.500" />
          <Text color="green.500" fontSize="sm" fontWeight="700">
            +2.45%
          </Text>
        </Flex> */}
      </Flex>
      <Box h="240px" mt="auto">
        <BarChart
          chartData={[
            {
              name: "Valor Investido",
              data: chartDataTitles,
            },
          ]}
          chartOptions={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            tooltip: {
              style: {
                fontSize: "12px",
                fontFamily: undefined,
              },
              onDatasetHover: {
                style: {
                  fontSize: "12px",
                  fontFamily: undefined,
                },
              },
              theme: "dark",
            },
            xaxis: {
              categories: chartRows,
              show: false,
              labels: {
                show: true,
                style: {
                  colors: "#A3AED0",
                  fontSize: "12px",
                  fontWeight: "500",
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              show: false,
              color: "black",
              logarithmic: true,
              logBase: 10000,
              labels: {
                show: true,
                style: {
                  colors: "#CBD5E0",
                  fontSize: "12px",
                },
                formatter: (val) =>
                  formatter.format(Math.floor(val * 100) / 100),
              },
            },
            grid: {
              show: false,
              strokeDashArray: 5,
              yaxis: {
                lines: {
                  show: true,
                },
              },
              xaxis: {
                lines: {
                  show: false,
                },
              },
            },
            fill: {
              type: "gradient",
              gradient: {
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                colorStops: [
                  [
                    {
                      offset: 0,
                      color: "#4318FF",
                      opacity: 1,
                    },
                    {
                      offset: 100,
                      color: "rgba(67, 24, 255, 1)",
                      opacity: 0.28,
                    },
                  ],
                ],
              },
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: "40px",
              },
            },
          }}
        />
      </Box>
    </Card>
  ) : null;
}
